import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Document from '../components/Document';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SidebarLogin from '../components/SidebarLogins';
import Wrapper from '../components/Wrapper';

const AccountingPage = ({ data }) => (
  <Layout>
    <Wrapper>
      <SEO title="Accounting Services" />
      <article className="max-w-2xl mx-auto w-full">
        <div className="max-w-md pt-4 px-4">
          <Link to="/accounting/">
            <h1 className="text-5xl">Accounting Services</h1>
          </Link>
          <hr />
        </div>
        <div className="flex flex-wrap">
          <div className="max-w-md pb-4 px-4 w-full">
            <h2>Benchmarking</h2>
            <p>
              To measure what you have done is important, Business Benchmarking
              solves two issues, firstly to analyse your business and see what
              you’re doing and how it’s affecting your goals. Secondly, it helps
              to track what your performance is in line with industry standards
              Benchmarking is the best way to analyse what has happened,
              allowing you to plan for the future.
            </p>

            <h2>Bookkeeping/Accounting</h2>
            <p>
              Bookkeeping and Accounting packages offered allow any client using
              any of the top 4 accounting packages to hand it all over to us,
              for the fraction of a fulltime bookkeeper, or saving yourself
              hours each week, let someone who knows their way around the tricky
              maze of software packages help you out. From processing payments,
              paying wages, entering invoices, reconciling bank accounts to
              sending out invoices and chasing your debtors. We offered a
              tailored solution for you to make life easier.
            </p>

            <h2>Budgeting</h2>
            <p>
              What are you going to spend and on what? Will it return to your
              business more than you spend? Budgeting is crucial, at any level
              it provides you with boundaries. We will help set the budgets with
              you so if you stick with it, you will achieve the end result you
              want.
            </p>

            <h2>Cash Flow Forecasting</h2>
            <p className="mb-0">What happens if...</p>
            <p>
              How much do you need to make to cover costs? Cashflow forecasting
              allows you to set your budgets and shows how much you’re going to
              need to cover the quiet times, how you can average out your income
              to even out your cash inflows and make the year as smooth as
              possible. You will know if you do X you will achieve Y.
            </p>

            <h2>Management Consulting</h2>
            <p>
              Are you leading by example? Our management consulting ensures the
              business has the direction and systems put in place to provide the
              guidance employees need. Everyone in the business manages
              something and if it's not done effectively the business will
              suffer. Make sure your resources, are optimized to enable the best
              possible performance.
            </p>

            <h2>Office Duties</h2>
            <p>
              Systems, Systems, Systems. Our specialised organisation processes
              — implemented in any business will ensure anyone new or existing
              in the business can perform any function. When a critical member
              is away or leaves these systems can be given to the new person who
              can step by step cover the process and complete it properly.
            </p>

            <h2>Payroll Services</h2>
            <p>
              Payroll, Awards, changes, nightmares. Our payroll service
              interprets the awards, pays the right amounts, provides everything
              with your required to provide and prepare and eliminates any fears
              when WorkCover or the ATO knock on the door. It takes all the time
              and effort out of processing the payroll and makes sure it is
              processed 100% correctly.
            </p>

            <h2>Software Solutions</h2>
            <p>
              Each software package has its own benefits and problems, we can
              help you work out which one is the most effective for you, and
              will ease the burden on your business. The majority of these
              programs are accounting programs that have been tweaked for small
              business, hence it can be a minefield if you select the wrong one.
            </p>

            <h2>Strategic Planning</h2>
            <p>
              Now you have a business and its running effectively, how and when
              are you going to get out of it, what’s your exit strategy. Is your
              business set up to minimize legally the tax you need to pay, how
              are you going to grow? Do you need to get bigger or do you need to
              be smaller and more profitable? Strategic planning covers so many
              areas, ultimately you need a strategy for every aspect of your
              business, and more than likely you have one, we can work with the
              current process, help make it better and make sure you are aware
              of how this strategy is going to be affected or effective in the
              future.
            </p>
          </div>
          <div className="flex-1 pb-4 px-4 w-full lg:w-1/4">
            <div className="lg:ml-24">
              <h2>Logins</h2>
              <SidebarLogin />
              <h2>Helpful Documents</h2>
              <ul className="list-reset leading-loose">
                <Document
                  link="/accounting/bookkeeping.pdf"
                  title="Bookkeeping"
                />
                <Document
                  link="/accounting/direct-debit.pdf"
                  title="Direct Debit"
                />
                <Document
                  link="/accounting/quickbooks.pdf"
                  title="Quickbooks"
                />
                <Document link="/accounting/myob.pdf" title="MYOB" />
                <Document
                  link="/accounting/company-secretarial.pdf"
                  title="Company Secretarial"
                />
              </ul>
              <Img fluid={data.imageAccounting.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </article>
    </Wrapper>
  </Layout>
);

AccountingPage.propTypes = {
  data: PropTypes.object,
};

export default AccountingPage;

export const pageQuery = graphql`
  query {
    imageAccounting: file(relativePath: { eq: "accounting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
